export const languagesServiceList = [
  {
    idLanguage: 4,
    name: "Deutsch",
    code: "de",
    flagUrl:
      "../../../assets/images/header/de-flag.svg",
  },
  {
    idLanguage: 2,
    name: "English",
    code: "en",
    flagUrl:
    "../../../assets/images/header/en-flag.svg",
  },
  {
    idLanguage: 1,
    name: "Español",
    code: "es",
    flagUrl:
    "../../../assets/images/header/es-flag.svg",
  },
  {
    idLanguage: 5,
    name: "عربيّة",
    code: "ar",
    flagUrl:
    "../../../assets/images/header/ar-flag.svg",

  },
  {
    idLanguage: 10,
    name: "中文 (簡體)",
    code: "zh-CN",
    flagUrl:
    "../../../assets/images/header/zh-flag.svg",

  },
  {
    idLanguage: 11,
    name: "中文 (繁體)",
    code: "zh-TW",
    flagUrl:
    "../../../assets/images/header/zh-flag.svg",
  },
  {
    idLanguage: 12,
    name: "עִבְרִית",
    code: "he",
    flagUrl:
    "../../../assets/images/header/he-flag.svg",
  },
  {
    idLanguage: 13,
    name: "Русский",
    code: "ru",
    flagUrl:
    "../../../assets/images/header/ru-flag.svg",
  },
];
