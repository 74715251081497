import { HostListener, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  public screenWidth: number;
  public isResponsiveMD: boolean = false;
  public isResponsiveSM: boolean = false;
  private isResponsiveSub = new Subject<{ mobile: boolean; tablet: boolean }>();
  public isResposiveView$ = this.isResponsiveSub.asObservable();

  constructor() {
    this.screenWidth = window.innerWidth;
  }
/* 
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.checkScreenSize();
  } */

  public checkScreenSize(screens: { mobile: boolean; tablet: boolean }) {
    this.isResponsiveSM = screens.mobile;
    this.isResponsiveMD = screens.tablet;
    this.isResponsiveSub.next(screens);
  }


}
