import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import * as AOS from 'aos';
import { fadeInDown } from 'ng-animate';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { MetaTagsService } from 'src/app/services/metaTags.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { FormComponent } from 'src/app/shared-components/form/form.component';
import { slideItem } from 'src/app/shared-components/models/shared.model';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
	standalone: true,
	animations: [trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown))])],
	imports: [CommonModule, TranslateModule, CarouselModule, TooltipModule, FormComponent],
})
export class HomeComponent implements OnInit, OnDestroy {
	@ViewChild('videoCrm') videoCrm!: ElementRef;
	@ViewChild('videoWebTrader') videoWebTrader!: ElementRef;
	@ViewChild('elementoConTooltip') elementoConTooltip: ElementRef;
	@ViewChild('trustfinanceWidget', { static: true }) widgetContainer!: ElementRef;

	private bsModalRef: BsModalRef;
	private homeSubs: Subscription[] = [];
	public crmTextActive: slideItem;
	public currentImageCrm: string = '';
	public currentImageTrading: string = '';
	public fadeInDown: any;
	public isResponsiveListened: boolean = false;
	public isVideo1Visible = false;
	public isVideo2Visible = false;
	public nameComponent: string = '';
	public openModal = false;
	public tradingTextActive: slideItem;
	public weOfferTextActive: slideItem;
	public isMobile: boolean = false;
	public isTablet: boolean = false;
	public technologies = [
		{ key: 'tech-angular', image: 'tech-angular', alt: 'angular icon', visible: false },
		{ key: 'tech-googlecloud', image: 'tech-googlecloud', alt: 'googlecloud icon', visible: false },
		{ key: 'tech-commpeak', image: 'tech-commpeak', alt: 'commpeak icon', visible: false },
		{ key: 'tech-email', image: 'tech-email', alt: 'email icon', visible: false },
		{ key: 'tech-aws', image: 'tech-aws', alt: 'aws icon', visible: false },
	];

	public tradingTexts = [
		{
			id: 0,
			title: 'titleClientOriented',
			code: 'clientOriented',
			active: false,
			text: {
				code: 'codeText',
				paragraph: 'paragraphClientOriented',
			},
		},
		{
			id: 1,
			title: 'titleIntegrationWithCrm',
			code: 'integrationWithCRM',
			active: false,
			text: {
				paragraph: 'paragraphIntegrationWithCrm',
			},
		},
		{
			id: 2,
			title: 'titleProfessionalTools',
			code: 'professionalTools',
			active: false,
			text: {
				paragraph: 'paragraphProfessionalTools',
			},
		},
		{
			id: 3,
			title: 'titleIntuitiveDesign',
			code: 'intuitiveDesign',
			active: false,
			text: {
				paragraph: 'paragraphIntuitiveDesign',
			},
		},
	];

	public crmTexts = [
		{
			id: 0,
			title: 'titleFocusOnDetails',
			code: 'focusOnDetails',
			active: false,
			text: {
				paragraph: 'paragraphFocusOnDetails',
			},
		},
		{
			id: 1,
			title: 'titleEasyManagement',
			code: 'easyManagement',
			active: false,
			text: {
				paragraph: 'paragraphEasyManagement',
			},
		},
		{
			id: 2,
			title: 'titleSalesUpgrade',
			code: 'salesUpgrade',
			active: false,
			text: {
				paragraph: 'paragraphSalesUpgrade',
			},
		},
		{
			id: 3,
			title: 'titleTrackYourTeam',
			code: 'trackYourTeam',
			active: false,
			text: {
				paragraph: 'paragraphTrackYourTeam',
			},
		},
	];

	public weOfferTexts = [
		{
			id: 0,
			title: 'titleCard1',
			code: 'smartCustomerRetention',
			active: false,
			image: '../../../assets/images/offer-icon-1.svg',
			text: {
				paragraph: 'paragraphCard1',
			},
		},
		{
			id: 1,
			title: 'titleCard2',
			code: 'orientedSolutions',
			active: false,
			image: '../../../assets/images/offer-icon-2.svg',
			text: {
				paragraph: 'paragraphCard2',
			},
		},
		{
			id: 2,
			title: 'titleCard3',
			code: 'improvedSegmentation',
			active: false,
			image: '../../../assets/images/offer-icon-3.svg',
			text: {
				paragraph: 'paragraphCard3',
			},
		},
		{
			id: 3,
			title: 'titleCard4',
			code: 'betterConsumerAwareness',
			active: false,
			image: '../../../assets/images/offer-icon-4.svg',
			text: {
				paragraph: 'paragraphCard4',
			},
		},
	];
	public activeTech: string | null = null;

	constructor(private modalService: BsModalService, private metaTagsService: MetaTagsService, private screenSizeService: ScreenSizeService) {
		this.tradingTextActive = this.tradingTexts[0];
		this.crmTextActive = this.crmTexts[0];
		this.weOfferTextActive = this.weOfferTexts[0];
		this.currentImageTrading = '../../../assets/images/' + this.tradingTexts[0].code + '.svg';
		this.currentImageCrm = '../../../assets/images/' + this.crmTexts[0].code + '.svg';
		this.checkScreenSize();

		this.isMobile = this.screenSizeService.isResponsiveSM;
		this.isTablet = this.screenSizeService.isResponsiveMD;

		const resizeSub = this.screenSizeService.isResposiveView$.subscribe((resResize) => {
			this.isMobile = resResize.mobile;
			this.isTablet = resResize.tablet;
		});

		this.homeSubs.push(resizeSub);
	}

	ngOnInit(): void {
		this.calculateSize();
		this.nameComponent = 'home';
		AOS.init();
		const title = 'WebTrader and CRM system for your brokerage';
		const description =
			'Unlock the full potential with Pentview! No usage limits, and zero installation costs. Access all CRM and WebTrader tools for top-notch brokerage operations.';
		const keywords = "brokerage package, Efficient installation process, White label solution, Streamlined brokerage management, Trader's room setup";
		this.metaTagsService.updateTags(title, description, keywords);
	}

	@HostListener('window:resize', ['$event']) private onResize(event: any): void {
		this.checkScreenSize();
	}
	public checkScreenSize() {
		this.isResponsiveListened = window.innerWidth < 993;
	}

	@HostListener('window:scroll') checkVideoVisibility() {
		const rect1 = this.videoCrm.nativeElement.getBoundingClientRect(); // Obtain (bottom - height - left - right - top - width - x - y) data
		const rect2 = this.videoWebTrader.nativeElement.getBoundingClientRect();
		const windowHeight = window.innerHeight;

		this.isVideo1Visible = rect1.top < windowHeight && rect1.bottom >= 0;
		this.isVideo2Visible = rect2.top < windowHeight && rect2.bottom >= 0;
	}

	public openRegisterFormModal(): void {
		this.openModal = true;
		const initialState = {
			initialState: {
				openModal: this.openModal,
			},
		};
		this.bsModalRef = this.modalService.show(FormComponent, initialState);
	}

	public changeSlideTrading(event: any): void {
		this.currentImageTrading = '../../../assets/images/' + event.code + '.svg';
	}

	public changeSlideCrm(event: any): void {
		this.currentImageCrm = '../../../assets/images/' + event.code + '.svg';
	}

	public zoomImage(imageId: string, modalZoomId: string, zoomImageId: string) {
		const imageToHover = document.getElementById(imageId) as HTMLImageElement;
		const modalZoomImage = document.getElementById(modalZoomId) as HTMLElement;
		const zoomImage = document.getElementById(zoomImageId) as HTMLImageElement;

		if (imageToHover) {
			modalZoomImage.style.display = 'block';

			const otherModals = document.querySelectorAll('.modal-zoom-image') as NodeListOf<HTMLElement>;
			otherModals.forEach((otherModal) => {
				if (otherModal.id !== modalZoomId) otherModal.style.display = 'none';
			});
			zoomImage.src = imageToHover.src;
		}
	}

	public zoomOutImage(modalZoomId: string, zoomImageId: string) {
		const modalZoomImage = document.getElementById(modalZoomId) as HTMLElement;
		const zoomImage = document.getElementById(zoomImageId) as HTMLImageElement;

		zoomImage.className += ' out';
		setTimeout(() => {
			modalZoomImage.style.display = 'none';
			zoomImage.className = 'zoom-image';
		}, 400);
	}

	@HostListener('window:resize', ['$event'])
	private onWindowSize(): void {
		this.calculateSize();
	}

	private calculateSize(): void {
		const isDesktop = window.innerWidth >= 768;
		const elements = document.querySelectorAll('.animate');

		if (isDesktop) {
			elements.forEach((element) => {
				element.classList.add('animate-start');
			});
		}
	}

	// Método reutilizable para obtener la ruta de la imagen
	getImageSrc(imageName: string, isActive: boolean): string {
		const suffix = isActive ? '-active' : '';
		return `../../../assets/images/home/${imageName}${suffix}.svg`;
	}

	// Método llamado cuando el ratón está sobre el elemento
	onMouseOver(tech: string): void {
		this.activeTech = tech;
	}

	// Método llamado cuando el ratón se retira del elemento
	onMouseOut(): void {
		this.activeTech = null;
	}

	onTechClick(tech: any): void {
		tech.visible = !tech.visible; // Alterna el estado de visibilidad
	}

	ngOnDestroy(): void {
		this.bsModalRef?.hide();
	}
}
