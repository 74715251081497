import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { Client } from '../models/client';

@Injectable({
	providedIn: 'root',
})
export class RegisterFormService {
	private urlApi = environment.urlApi;

	constructor(private http: HttpClient) {}

	/**
	 * Method for register external clients / potentials companies
	 * @param client data for register client
	 * @returns
	 */
	public registerClient(client: Client): Observable<{ message: string }> {
		const currentLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
		const headers = new HttpHeaders().set('hash-code', 'aGFzaC1jbGllbnQtcGVudHZpZXc').set('Accept-Language', currentLanguage!);

		return this.http.post<{ message: string }>(`${this.urlApi}/ms-affiliates/external-register/client`, client, { headers });
	}
}
