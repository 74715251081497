import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { OffcanvasService } from 'src/app/services/offcanvas.service';
import { ScreenSizeService } from 'src/app/services/screen-size.service';
import { environment } from 'src/environments/environment.dev';
import { FormComponent } from '../form/form.component';
import { languagesServiceList } from '../utils/languages';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	private headerSubs: Subscription[] = [];

	private bsModalRef: BsModalRef;
	public openModal = false;
	public languagesList = languagesServiceList;
	public languageTypeList: {
		idLanguage: number;
		name: string;
		code: string;
		flagUrl: string;
	}[];
	public selectedLanguage: {
		idLanguage: number;
		name: string;
		code: string;
		flagUrl: string;
	};
	public language: {
		idLanguage: number;
		name: string;
		code: string;
		flagUrl: string;
	};

	public pages: {
		route: string;
		active: boolean;
	}[] = [];

	public isNavbarCollapsed = true;
	public isMobile: boolean = false;
	public isTablet: boolean = false;
	constructor(
		private modalService: BsModalService,
		private translateService: TranslateService,
		private offcanvasService: OffcanvasService,
		private screenSizeService: ScreenSizeService,
	) {
		this.isMobile = this.screenSizeService.isResponsiveSM;
		this.isTablet = this.screenSizeService.isResponsiveMD;

		const resizeSub = this.screenSizeService.isResposiveView$.subscribe((resResize) => {
			this.isMobile = resResize.mobile;
			this.isTablet = resResize.tablet;
		});
		this.headerSubs.push(resizeSub);
	}

	ngOnInit(): void {
		this.getLanguage();
		this.listenChangesScreen();
	}

	@HostListener('window:resize')
	listenChangesScreen(): void {
		const screenWidth = window.innerWidth;

		if (screenWidth < 768) {
			// Check if it is mobile view
			this.screenSizeService.checkScreenSize({ mobile: true, tablet: false });
		} else if (screenWidth >= 768 && screenWidth <= 992) {
			// Check if it is tablet view
			this.screenSizeService.checkScreenSize({ mobile: false, tablet: true });
		} else {
			this.screenSizeService.checkScreenSize({ mobile: false, tablet: false });
		}
	}

	/* 	isMobile(): boolean {
		return window.innerWidth < 992; // Verifica si el ancho de la ventana es menor que 992 píxeles
	} */
	toggleOffcanvas() {
		this.offcanvasService.toggleOffcanvas();
	}
	public changeState(route: string): void {
		this.pages = this.pages.map((page) => {
			if (route === page.route) {
				return { ...page, active: true };
			} else {
				return { ...page, active: false };
			}
		});
	}

	public showToggleNavigation(): void {
		this.isNavbarCollapsed = !this.isNavbarCollapsed;
	}

	public openRegisterFormModal(): void {
		this.openModal = true;
		const initialState = {
			initialState: {
				openModal: this.openModal,
			},
		};
		this.bsModalRef = this.modalService.show(FormComponent, initialState);
	}

	public getLanguage(): void {
		const defaultLanguage = {
			idLanguage: 2,
			name: 'English',
			code: 'en',
			flagUrl: '../../../assets/images/header/en-flag.svg',
		};
		this.languageTypeList = languagesServiceList;
		this.selectedLanguage = environment.languages.includes(localStorage.getItem('language')!)
			? this.languagesList.filter((lang) => lang.code === localStorage.getItem('language'))[0]
			: defaultLanguage;
	}

	public changeLanguage(language: { idLanguage: number; name: string; code: string; flagUrl: string }): void {
		this.selectedLanguage = language;
		this.language = {
			idLanguage: this.selectedLanguage.idLanguage,
			name: this.selectedLanguage.name,
			code: this.selectedLanguage.code,
			flagUrl: this.selectedLanguage.flagUrl,
		};
		environment.languages.includes(this.language.code) ? language.code : 'en';
		this.translateService.use(language.code);
		localStorage.setItem('language', language.code);
	}

	ngOnDestroy(): void {
		this.bsModalRef.hide();
	}
}
