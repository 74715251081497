import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'webtrader-and-crm-system-for-you-brokerage', redirectTo: '', pathMatch: 'full' },
  { path: 'about-us', loadComponent: () => import('./components/about-us/about-us.component').then(m => m.AboutUsComponent) },
  { path: 'contacts-us', loadComponent: () => import('./components/contacts-us/contacts-us.component').then(m => m.ContactsUsComponent) },
  { path: 'services/empower-your-connections-with-pentviews-crm-system', loadComponent: () => import('./components/crm-system/crm-system.component').then(m => m.CrmSystemComponent) },
  { path: 'services/optimize-your-brokerage-with-pentviews-webtrader', loadComponent: () => import('./components/web-trader/web-trader.component').then(m => m.WebTraderComponent) },
  { path: 'services/additional-services-for-your-brokerage', loadComponent: () => import('./components/additional-services/additional-services.component').then(m => m.AdditionalServicesComponent) },
  { path: 'presentation', loadComponent: () => import('./components/presentation/presentation.component').then(m => m.PresentationComponent) },
  { path: 'templates', loadComponent: () => import('./components/templates/templates.component').then(m => m.TemplatesComponent) },
  { path: 'instruments', loadComponent: () => import('./components/instruments/instruments.component').then(m => m.InstrumentsComponent) },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
