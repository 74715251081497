import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-dialog-error',
	template: `
		<div class="modal-header">
			<h4 class="modal-title">{{ 'SHARED.dialogError.warning' | translate }}</h4>
		</div>
		<div class="modal-body">
			<p class="text-center text-white mb-0">{{ message }}</p>
		</div>
		<div class="modal-footer justify-content-center">
			<button type="button" class="btn-custom-danger" (click)="dialogErrorModal.hide()">
				{{ 'SHARED.dialogError.ok' | translate }}
			</button>
		</div>
	`,
	styles: [],
})
export class DialogErrorComponent {
	public message: string = '';

	constructor(public dialogErrorModal: BsModalRef) {}
}
