<div class="container-fluid overflow-hidden p-0">
	<div class="row">
		<div class="col-12 col-header">
			<app-header></app-header>
		</div>
		<div class="col-12 overflow-hidden px-0" *ngIf="!isOffcanvasOpen">
			<router-outlet></router-outlet>
		</div>
		<div class="col-12 px-0" *ngIf="!isOffcanvasOpen">
			<app-footer></app-footer>
		</div>
	</div>
</div>
