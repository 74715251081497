import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { OffcanvasService } from './services/offcanvas.service';
import { ThemeService } from './services/ThemeService.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnDestroy {
	title = 'pentview-landing';
	isOffcanvasOpen: boolean = false;
	private offcanvasSubscription: Subscription;
	constructor(private offcanvasService: OffcanvasService, private themeService: ThemeService, private translate: TranslateService) {
		this.offcanvasSubscription = this.offcanvasService.isOffcanvasOpen.subscribe((isOpen) => {
			this.isOffcanvasOpen = isOpen;
		});
	}

	ngOnInit() {
		const currentLanguage = localStorage.getItem('language');
		if (!currentLanguage) {
			localStorage.setItem('language', 'en');
		}
		this.translate.use(environment.languages.includes(currentLanguage!) ? currentLanguage! : 'en');
	}

	ngOnDestroy() {
		this.offcanvasSubscription.unsubscribe();
	}
}
