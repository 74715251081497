<footer class="footer">
	<div class="container-row d-flex row jstify-content-between m-0">
		<!-- logo -->
		<div class="footer-logo col-12 col-lg-2 px-0 pb-md-5 pb-sm-5">
			<a href="index.html">
				<img src="../../../assets/images/footer/Logo.svg" alt="pentview big logo" width="145" height="79" />
			</a>
		</div>
		<!-- Navigation -->
		<div class="col-12 col-lg-10 p-0 mt-4 mt-md-0">
			<div class="d-flex row justify-content-center justify-content-lg-between align-items-start m-0">
				<!-- main products -->
				<div class="contacts col-6 col-md-3 col-lg-3 p-0">
					<h3 class="footer-subtitle text-white fw-bold mb-2 mb-lg-3">
						{{ 'SHARED.footer.texts.mainProducts' | translate }}
					</h3>
					<div class="footer-list">
						<a href="/services/empower-your-connections-with-pentviews-crm-system" class="main-products-links mb-2 text-white">CRM</a>
						<a href="/services/optimize-your-brokerage-with-pentviews-webtrader" class="main-products-links mb-2 text-white">WebTrader</a>
						<a class="main-products-links text-white" href="/webtrader-and-crm-system-for-you-brokerage">{{
							'SHARED.footer.texts.website' | translate
						}}</a>
					</div>
				</div>
				<!-- navigation -->
				<div class="contacts col-6 col-md-3 col-lg-3 p-0">
					<h3 class="footer-subtitle text-white fw-bold mb-2 mb-lg-3">
						{{ 'SHARED.footer.texts.navigation' | translate }}
					</h3>
					<div class="footer-list">
						<a href="/webtrader-and-crm-system-for-you-brokerage" routerLinkActive="active" class="navigation-links mb-2">{{
							'SHARED.header.home' | translate
						}}</a>
						<a href="/services/empower-your-connections-with-pentviews-crm-system" routerLinkActive="active" class="navigation-links mb-2">
							{{ 'SHARED.header.services' | translate }}
						</a>
						<a href="/about-us" routerLinkActive="active" class="navigation-links mb-2">{{ 'SHARED.header.aboutUs' | translate }}</a>
						<a href="/contacts-us" routerLinkActive="active" class="navigation-links mb-2">{{ 'SHARED.header.contactsUs' | translate }}</a>
					</div>
				</div>
				<!-- contacts -->
				<div class="contacts col-12 col-md-3 col-lg-3 p-0">
					<h3 class="footer-subtitle text-white fw-bold mb-2 mb-lg-3">
						{{ 'SHARED.footer.texts.contacts' | translate }}
					</h3>
					<div class="footer-list">
						<a href="tel:+380973007491" class="mb-2 text-white contacts-links" target="_blank">
							<img src="../../../assets/images/footer/telephone-call.svg" alt="phone icon" class="me-1" width="25" height="24" />
							<span>+380973007491</span>
						</a>
						<a href="mailto:hello@pentview.com" class="mb-2 text-white contacts-links">
							<img src="../../../assets/images/footer/email.svg" alt="phone-icon" class="me-1" width="25" height="24"/><span>hello@pentview.com</span>
						</a>
						<a class="text-white contacts-links" href="https://goo.gl/maps/YEuUCWHTRN3tYj6C8" target="_blank">
							<img src="../../../assets/images/footer/Frame 9519.svg" alt="phone-icon" class="me-1" width="25" height="24" /><span>Klovs'kyi descent, 7a</span>
						</a>
					</div>
				</div>
				<!-- social media -->
				<div class="follow col-12 col-lg-3 p-0 my-5 my-lg-0">
					<h3 class="footer-subtitle text-white fw-bold text-center mb-2 mb-lg-3">
						{{ 'SHARED.footer.texts.socialMedia' | translate }}
					</h3>
					<div class="links-social-media-container text-center">
						<a href="https://www.facebook.com/PentView" target="_blank">
							<img src="../../../assets/images/footer/free-icon-facebook-2504903.svg" alt="facebook icon" width="24" height="24" />
						</a>
						<a href="https://www.instagram.com/pentview_systems/" target="_blank">
							<img src="../../../assets/images/footer/free-icon-instagram-2504918.svg" alt="instagram icon" width="24" height="24" />
						</a>
						<a href="https://www.linkedin.com/company/pentview-trading-crm-system-white-label" target="_blank">
							<img src="../../../assets/images/footer/free-icon-linkedin-2504923.svg" alt="linkedin icon" width="24" height="24" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- terms and conditions - privacy - cookies -->
	<div class="container-row row my-4">
		<div class="col-lg-6 col-12">
			<a href="../../../assets/docs/Terms-and-Conditions-Pentview.pdf" target="_blank" class="text-privacy border-privacity pe-2 border-end">{{
				'SHARED.footer.generalConditions.termsAndConditions' | translate
			}}</a>
			<a href="../../../assets/docs/Privacy-Policy-Pentview.pdf" target="_blank" class="text-privacy border-privacity px-2 border-end">{{
				'SHARED.footer.generalConditions.privacyPolicy' | translate
			}}</a>
			<a href="../../../assets/docs/Cookie-Policy-Pentview.pdf" target="_blank" class="text-privacy px-2 border-end">{{
				'SHARED.footer.generalConditions.cookiesPolicy' | translate
			}}</a>
		</div>
		<div class="col-lg-6 d-lg-flex d-none justify-content-end ">
			<div class="tooltip-container">
				<img
					(click)="scrollToTop()"
					class="goUp"
					src="../../../assets/images/footer/Double Arrow Up (1).svg"
					alt="go to top icon"
					width="53"
					height="53"
				/>
				<span class="tooltip-text"> {{ 'SHARED.footer.tooltip.goUp' | translate }}</span>
			</div>
		</div>
		<p class="text-privacy" style="margin-top: 10px;">
			{{ 'SHARED.footer.generalConditions.allRightsReserved' | translate }}
		</p>
	</div>
	<!-- advertisement -->
	<div class="container-row row">
		<p class="text-privacy">{{ 'SHARED.footer.advertisement.text' | translate }}</p>
	</div>
</footer>
