import { CommonModule, PRECONNECT_CHECK_BLOCKLIST, NgOptimizedImage } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppRoutingModule } from './app-routing.module';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment.dev';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactsUsComponent } from './components/contacts-us/contacts-us.component';
import { CrmSystemComponent } from './components/crm-system/crm-system.component';
import { ErrorComponent } from './components/error/error.component';
import { InstrumentsComponent } from './components/instruments/instruments.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { WebTraderComponent } from './components/web-trader/web-trader.component';
import { HomeComponent } from './pages/home/home.component';
import { DialogErrorComponent } from './shared-components/dialog-error/dialog-error.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { FormComponent } from './shared-components/form/form.component';
import { HeaderComponent } from './shared-components/header/header.component';
import { AdditionalServicesComponent } from './components/additional-services/additional-services.component';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
export function language(): string {
	return environment.languages.includes(localStorage.getItem('language')!) ? localStorage.getItem('language')! : 'en';
}

@NgModule({
	declarations: [
		//AboutUsComponent,
		//AdditionalServicesComponent,
		AppComponent,
		//ContactsUsComponent,
		//CrmSystemComponent,
		DialogErrorComponent,
		FooterComponent,
		//FormComponent,
		HeaderComponent,
		//HomeComponent,
		//PresentationComponent,
		//TemplatesComponent,
		// WebTraderComponent,
		//InstrumentsComponent,
		//ErrorComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		BsDropdownModule.forRoot(),
		CommonModule,
		NgOptimizedImage,
		FormsModule,
		HttpClientModule,
		ModalModule.forRoot(),
		Ng2TelInputModule,
		ReactiveFormsModule,
		CarouselModule,
		PdfViewerModule,
		TranslateModule.forRoot({
			defaultLanguage: language(),
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		TooltipModule,
	],
	providers: [{ provide: PRECONNECT_CHECK_BLOCKLIST, useValue: ['https://pentview-resource.s3.eu-central-1.amazonaws.com'] }],
	bootstrap: [AppComponent],
})
export class AppModule { }
