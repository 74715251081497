import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {
    this.detectTheme();
  }

  detectTheme(): void {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    this.updateFavicon(darkModeMediaQuery.matches); // LISTEN THEME

    darkModeMediaQuery.addEventListener('change', (e) => {
      this.updateFavicon(e.matches); // Change favicon when chenge theme
    });
  }

  updateFavicon(isDarkMode: boolean): void {
    const favicon = document.getElementById('app-favicon') as HTMLLinkElement;
    favicon.href = isDarkMode ? 'assets/favicon-dark.ico' : 'assets/favicon-light.ico';
  }
}
