<div class="margin-section-100 text-center">
    <h1 class="title-web fw-bold pb-3" style="color: #2bbc65;">
        {{ "ERROR.alert" | translate }}
    </h1>
    <h2 class="text-32">
       {{ "ERROR.subtitle" | translate }}
    </h2>
    <div class=" d-md-flex d-none margin-top-120 justify-content-center">
        <img src="../../../assets/images/404/Pic.svg" alt="404 page not found" >
        
    </div>
     <div class=" d-md-none d-flex margin-top-120 justify-content-center">
        <img src="../../../assets/images/404/small.svg" alt="404 page not found" >
        
    </div>
</div>

