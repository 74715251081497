import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor() { }

  irArriba() {
    const duration = 100; // Duración de la animación en milisegundos
    const start = window.pageYOffset;
    const target = 0;
    const distance = target - start;
    const startTime = performance.now();

    function step(currentTime: number) {
      const elapsedTime = currentTime - startTime;
      window.scrollTo(0, easeInOut(elapsedTime, start, distance, duration));

      if (elapsedTime < duration) {
        requestAnimationFrame(step);
      }
    }

    function easeInOut(t: number, b: number, c: number, d: number) {
      t /= d / 2;
      if (t < 1) {
        return c / 2 * t * t + b;
      }
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(step);
  }
}
