import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OffcanvasService {
    private isOffcanvasOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }

    toggleOffcanvas(): void {
        this.isOffcanvasOpenSubject.next(!this.isOffcanvasOpenSubject.value);
    }

    get isOffcanvasOpen(): Observable<boolean> {
        return this.isOffcanvasOpenSubject.asObservable();
    }
}
