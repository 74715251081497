<!-- Section 1 - WebTrader and CRM -->
<section class="section-1 padding-section overflow-hidden">
  <div
    class="container-row d-flex row justify-content-between align-items-start"
  >
    <div class="col-text-section-1 col-12 col-sm-12 col-md-12 col-lg-6 p-0">
      <!-- title for web view -->
      <h1 class="title-section-1 text-md-center text-lg-start m-0 pb-0">
        {{ "HOME.section1.webTraderTitle" | translate }}
        <br />
        {{ "HOME.section1.crmTitle" | translate }}
      </h1>
      <!-- title for mobile view -->
      <!-- <h1 class="d-block d-sm-none d-md-none title-section-1 text-md-center text-lg-start m-0 pb-2">
				<div class="title-section-1" [@fadeInDown]="{ value: fadeInDown, params: { timing: 1 } }">
					{{ 'HOME.section1.webTraderTitle' | translate }} <br />
				</div>
				<div class="title-section-1 text-end" [@fadeInDown]="{ value: fadeInDown, params: { timing: 1 } }">
					{{ 'HOME.section1.crmTitle' | translate }}
				</div>
			</h1> -->
      <!-- subtitle -->
      <h2 class="sub-title-section-1 m-0 py-4">
        {{ "HOME.section1.textSection1" | translate }}
      </h2>
      <p class="text-white free-trial-text m-0 pb-3">
        {{ "HOME.section1.getText" | translate }}
        <span class="text-light-blue">{{
          "HOME.section1.freeText" | translate
        }}</span>
        {{ "HOME.section1.trialText" | translate }}
      </p>
      <div class="col-12 p-0">
        <app-form
          [fieldsNumber]="2"
          [section]="nameComponent"
          class="d-flex justify-content-center justify-content-lg-start"
        ></app-form>
      </div>
    </div>
    <div
      class="d-flex col-images-section-1 justify-content-center align-items-center col-12 col-sm-12 col-md-12 col-lg p-0 my-auto"
      [ngClass]="{'mt-3': isMobile}"
    >
    <img
      srcset="
        ../../../assets/images/home/img-section-1-mobile.svg 480w,
        ../../../assets/images/home/img-section-1.webp 800w
      "
      sizes="(max-width: 575px) 480px, 800px"
      [src]="!isMobile ? '../../../assets/images/home/img-section-1.webp' : '../../../assets/images/home/img-section-1-mobile.svg'"
      alt="Different tools are offered by our WebTrader in Pentview for data exploration"
      class="img-fluid px-0"
      loading="lazy"
    />
    </div>
  </div>
</section>
<!-- Section 2 - Pentview means -->
<section class="home-section section-2 padding-section">
  <div
    class="container-row d-flex row justify-content-between align-items-center"
  >
    <h2 class="title-section title-h2 text-center mx-0 p-0">
      {{ "HOME.section2.text.pentviewMeans" | translate }}
    </h2>
    <div class="d-flex row justify-content-between align-items-center m-0 p-0">
      <!-- meanings left container -->
      <div class="meanings-left-container col-12 col-lg-3 p-0">
        <!-- row meanings -->
        <div
          class="row-meanings d-flex row justify-content-between align-items-center m-0"
        >
          <!-- meaning 1 -->
          <div
            [ngStyle]="
              !isResponsiveListened ? { 'margin-bottom': '114px' } : {}
            "
            class="meaning-1 col-12 col-md-6 col-lg-12 row justify-content-start justify-content-lg-end mx-0 ps-0 pe-5 pe-md-3 p-lg-0"
          >
            <p
              class="text-mean m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip1' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean1" | translate }}
            </p>
            <p class="m-0 p-0 " *ngIf="isResponsiveListened" style="font-size: 15px; font-family: 'Poppins-Light'; color: #fff;">
              {{ "HOME.section2.toolTips.toolTip1" | translate }}
            </p>
          </div>
          <!-- meaning 2 -->
          <div
            [ngStyle]="
              !isResponsiveListened ? { 'margin-bottom': '121px' } : {}
            "
            class="meaning-2 col-12 col-md-6 col-lg-12 row justify-content-start justify-content-lg-end mx-0 ps-0 pe-5 p-md-0 ps-lg-3 p-lg-0"
          >
            <p
              class="text-mean m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip2' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean2" | translate }}
            </p>
            <p class="m-0 p-0" *ngIf="isResponsiveListened">
              {{ "HOME.section2.toolTips.toolTip2" | translate }}
            </p>
          </div>
          <!-- meaning 3 -->
          <div
            [ngStyle]="
              !isResponsiveListened ? { 'margin-bottom': '121px' } : {}
            "
            class="meaning-3 col-12 col-md-6 col-lg-12 row justify-content-start justify-content-lg-end mx-0 ps-0 pe-5 ps-md-5 p-lg-0"
          >
            <p
              class="text-mean m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip3' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean3" | translate }}
            </p>
            <p class="m-0 p-0" *ngIf="isResponsiveListened">
              {{ "HOME.section2.toolTips.toolTip3" | translate }}
            </p>
          </div>
          <!-- meaning 4 -->
          <div
            [ngStyle]="
              !isResponsiveListened ? { 'margin-bottom': '100px' } : {}
            "
            class="meaning-4 col-12 col-md-6 col-lg-12 row justify-content-start justify-content-lg-end mx-0 ps-0 pe-5 ps-md-5 p-lg-0"
          >
            <p
              class="text-mean m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip4' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean4" | translate }}
            </p>
            <p class="m-0 p-0" *ngIf="isResponsiveListened">
              {{ "HOME.section2.toolTips.toolTip4" | translate }}
            </p>
          </div>
        </div>
      </div>
      <!-- meanings right container -->
      <div class="meanings-right-container col-12 col-lg-3 p-0">
        <!-- row meanings -->
        <div
          class="row-meanings d-flex row justify-content-between align-items-center mx-0"
        >
          <!-- meaning 5 -->
          <div
            class="col-12 col-md-6 col-lg-12 ps-0 pe-5 ps-md-0 pe-md-5 p-lg-0"
            [ngStyle]="
              !isResponsiveListened
                ? { 'margin-bottom': '48px' }
                : { 'margin-bottom': '0' }
            "
          >
            <p
              class="text-mean text-start m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip5' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean5" | translate }}
            </p>
            <p class="text-start" *ngIf="isResponsiveListened">
              {{ "HOME.section2.toolTips.toolTip5" | translate }}
            </p>
          </div>
          <!-- meaning 6 -->
          <div
            class="col-12 col-md-6 col-lg-12 ps-0 pe-5 ps-md-5 pe-md-0 p-lg-0"
            [ngStyle]="
              !isResponsiveListened
                ? { 'margin-bottom': '101px' }
                : { 'margin-bottom': '0' }
            "
          >
            <p
              class="text-mean text-start m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip6' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean6" | translate }}
            </p>
            <p class="text-start" *ngIf="isResponsiveListened">
              {{ "HOME.section2.toolTips.toolTip6" | translate }}
            </p>
          </div>
          <!-- meaning 7 -->
          <div 
            class="meaning-7 col-12 col-md-6 col-lg-12 mx-auto ps-0 pe-5 px-md-5 p-lg-0"
            [ngStyle]="
              !isResponsiveListened
                ? { 'margin-bottom': '160px' }
                : { 'margin-bottom': '0' }
            "
          >
            <p
              class="text-mean text-start m-0"
              [tooltip]="'HOME.section2.toolTips.toolTip7' | translate"
              placement="top"
              [hideDelay]="100"
              maxWidth="320px"
              [display]="isResponsiveListened ? false : true"
            >
              {{ "HOME.section2.text.pentviewMean7" | translate }}
            </p>
            <p class="text-start" *ngIf="isResponsiveListened">
              {{ "HOME.section2.toolTips.toolTip7" | translate }}
            </p>
          </div>
        </div>
      </div>



    </div>
    
  </div>
</section>
<!-- Section 2.1 - Techs Pentview -->
<section class="section-tech">
  <ng-container *ngIf="isMobile || isTablet">
    <div
      class="d-flex row justify-content-between align-items-center m-0 p-0"
    >
      <img
        src="../../../assets/images/home/img-home-section-2-end.svg"
        title="lines "
        alt="Lines image"
        width="135"
        height="233"
        loading="lazy"
      />
    </div>
  </ng-container>
  <div
    class="row justify-content-center mb-4 px-0 mx-0 pb-4"
    [ngStyle]="{ gap: isTablet ? '48px' : isMobile ? '0px' : '72px' }"
  >
    <ng-template #techsWeb>
      <div
        *ngFor="let tech of technologies"
        class="col-md-auto col-sm-6 col-6 d-flex justify-content-center px-0"
      >
        <p
          *ngIf="!isMobile"
          class="text-technology m-0"
          [tooltip]="
            'HOME.section2.technology-tooltips.' + tech.key | translate
          "
          placement="bottom"
          [hideDelay]="100"
          maxWidth="320px"
          [tooltipClass]="'custom-tooltip-section2'"
          (mouseover)="onMouseOver(tech.key)"
          (mouseout)="onMouseOut()"
        >
          <img
            [src]="getImageSrc(tech.image, activeTech === tech.key)"
            [alt]="tech.alt"
            width="96"
            height="96"
            loading="lazy"
          />
        </p>
      </div>
    </ng-template>

    <ng-container *ngIf="isMobile || isTablet; else techsWeb">
      <div
        *ngFor="let tech of technologies"
        class="mt-5 d-flex align-item-center"
        [ngClass]="{ 
          'col-12 ': isMobile && tech.visible,
          'col-5 ': isTablet && tech.visible,
          'col-auto justify-content-center':  !tech.visible
          }"
        style="display: flex !important;
        flex-direction: column;
        align-items: center;"
      >
        <img
          [src]="getImageSrc(tech.image, tech.visible)"
          [alt]="tech.alt"
          width="96"
          height="96"
          loading="lazy"
          (click)="onTechClick(tech)"
        />
        <p class="text-technology m-0 text-center" *ngIf="tech.visible">
          {{ "HOME.section2.technology-tooltips." + tech.key | translate }}
        </p>
      </div>
    </ng-container>
  </div>
</section>
<!-- section 3 - How can we help you? -->
<section class="consultation">
  <div class="d-flex row justify-content-between align-items-center mt-3 mx-0">
    <h2 class="title-free text-green mx-0">
      {{ "HOME.consultation.titleConsultation" | translate }}
    </h2>
    <h2 class="title-free text-white mx-0">
      {{ "HOME.consultation.titleSuccess" | translate }}
    </h2>
    <p class="text mt-4 mx-0">{{ "HOME.consultation.textProvide" | translate }}</p>
    <div class="d-flex"
    [ngClass]="{ 'justify-content-center': isMobile }"
    >
      <button
        title="Open"
        class="mt-4 btn-consultation text-white d-lg-block"
        (click)="openRegisterFormModal()"
      >
        {{ "HOME.consultation.titleConsultation" | translate }}
      </button>
    </div>
  </div>
</section>
<section class="home-section section-3 padding-section">
  <div class="widget-container">
    <div
      #trustfinanceWidget
      class="trustfinance-widget"
      data-template-id="1"
      data-company-id="pentview"
      data-style-height="500px"
      data-style-width="100%"
      data-theme="dark"
    ></div>
  </div>

  <div
    class="container-row d-flex row justify-content-between align-items-center mt-3"
  >
    <h2 class="title-section title-h2 text-center mx-0 p-0">
      {{ "HOME.section3.howCanWeHelpYou" | translate }}
    </h2>
    <h3 class="text-center title-h3 margin-bottom-80 mx-0 p-0">
      {{ "HOME.section3.subTitle1Section" | translate }}
      <br />
      {{ "HOME.section3.subTitle2Section" | translate }}
    </h3>
    <div class="col-12 p-0">
      <div class="card">
        <div class="row g-0">
          <div
            class="col-md-7 order-1 order-lg-0 ps-lg-0 pe-lg-5 py-0"
            #videoCrm
          >
            <video
              *ngIf="isVideo1Visible"
              [muted]="true"
              autoplay
              class="w-100"
              data-aos="fade-down"
              loop
              playsinline
              disablePictureInPicture
              poster="../../../assets/images/home/img-poster-crm.webp"
              alt="Data filtration for better management of internal information within the CRM"
            >
              <source src="../../../assets/images/home/CRM.mp4" />
            </video>
          </div>
          <div class="col-md-5 order-0 order-lg-1 mb-5 mb-lg-0 p-0">
            <div class="card-body ps-lg-5 pe-lg-0 p-0">
              <h4 class="card-title">
                {{ "HOME.section3.crmSystemInfo.crmSystem" | translate }}
              </h4>
              <p class="card-text">
                {{ "HOME.section3.crmSystemInfo.crmSystemText1" | translate }}
              </p>
              <p class="card-text">
                {{ "HOME.section3.crmSystemInfo.crmSystemText2" | translate }}
              </p>
              <a
                href="/services/empower-your-connections-with-pentviews-crm-system"
              >
                <button title="CRM-system" class="get-trial-button">
                  {{ "HOME.section3.learnMore" | translate }}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="card margin-top-120">
        <div class="row g-0">
          <div class="col-md-5 p-0">
            <div class="card-body mb-5 mb-lg-0 ps-lg-0 pe-lg-5 p-0">
              <h4 class="card-title">
                {{ "HOME.section3.webTraderInfo.webTrader" | translate }}
              </h4>
              <p class="card-text">
                {{ "HOME.section3.webTraderInfo.webTraderText1" | translate }}
              </p>
              <p class="card-text">
                {{ "HOME.section3.webTraderInfo.webTraderText2" | translate }}
              </p>
              <a
                href="/services/optimize-your-brokerage-with-pentviews-webtrader"
              >
                <button title="WebTrader" class="get-trial-button">
                  {{ "HOME.section3.learnMore" | translate }}
                </button>
              </a>
            </div>
          </div>
          <div class="col-md-7 ps-lg-5 pe-lg-0 py-0" #videoWebTrader>
            <video
              *ngIf="isVideo2Visible"
              [muted]="true"
              autoplay
              class="w-100"
              data-aos="fade-down"
              loop
              playsinline
              disablePictureInPicture
              poster="../../../assets/images/home/img-poster-webtrader.webp"
              alt="Basic candlestick system for better understanding of the different markets we offer, real-time analysis"
            >
              <source
                src="../../../assets/images/home/web-trader.m4v"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section 4 - How do we works? -->
<section class="home-section section-4 padding-section">
  <div
    class="container-row d-flex row justify-content-between align-items-center"
  >
    <h2 class="text-center title-h2">
      {{ "HOME.section4.howDoWeWorks" | translate }}
    </h2>
    <h3 class="text-center title-h3">
      {{ "HOME.section4.fulfilling" | translate }}
    </h3>
    <!-- steps sub-section -->
    <div class="row m-0 p-0">
      <div class="col-sm-4 m-0 ps-0 pe-lg-5 py-0">
        <div class="card">
          <div class="card-body m-0 p-0" data-aos="fade-down">
            <h4 class="card-title">
              {{ "HOME.section4.step" | translate }}
              <span class="step-number ms-lg-5">{{
                "HOME.section4.numberStep1" | translate
              }}</span>
            </h4>
            <p class="card-text">{{ "HOME.section4.contactUs" | translate }}</p>
            <p class="card-text">
              {{ "HOME.section4.stayConnected" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 m-0 px-lg-5 py-0">
        <div class="card">
          <div
            class="card-body m-0 p-0"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            <h4 class="card-title">
              {{ "HOME.section4.step" | translate }}
              <span class="step-number ms-lg-5">{{
                "HOME.section4.numberStep2" | translate
              }}</span>
            </h4>
            <p class="card-text">{{ "HOME.section4.discover" | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 m-0 ps-lg-5 pe-0 py-0">
        <div class="card">
          <div
            class="card-body m-0 p-0"
            data-aos="fade-down"
            data-aos-delay="600"
          >
            <h4 class="card-title">
              {{ "HOME.section4.step" | translate }}
              <span class="step-number ms-lg-5">{{
                "HOME.section4.numberStep3" | translate
              }}</span>
            </h4>
            <p class="card-text">
              {{ "HOME.section4.prepareYourPlatform" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- animations sub-section -->
    <div
      class="points-container d-flex justify-content-between justify-content-lg-around align-items-center mx-0"
    >
      <div class="point p-0"></div>
      <span class="progress-bar"></span>
      <div class="point p-0"></div>
      <div class="point p-0"></div>
    </div>
  </div>
</section>
<!-- Section 5 - Extraordinary treat -->
<section class="home-section section-5 padding-section">
  <h2
    class="title-h2 text-sm-center text-start text-md-center text-lg-start mt-0"
  >
    {{ "HOME.section5.extraordinaryTreat" | translate }}
  </h2>
  <div
    class="container-row d-flex row justify-content-between align-items-center"
  >
    <div class="col-12 col-lg-6 mb-5 mb-lg-0 p-0 pe-lg-5">
      <h3
        class="title-h3 text-sm-center text-start text-md-center text-lg-start"
      >
        {{ "HOME.section5.getTheFastSetUp" | translate }}
      </h3>
      <p
        class="text-white text-sm-center text-start text-md-center text-lg-start"
      >
        {{ "HOME.section5.bearInMind" | translate }}
      </p>
      <app-form
        [fieldsNumber]="2"
        [section]="nameComponent"
        class="d-flex justify-content-center justify-content-lg-start"
      ></app-form>
    </div>
    <div class="col-12 col-lg-6 m-0 p-0 ps-lg-5">
      <img
        (click)="
          zoomImage('image-to-hover-s5', 'modal-zoom-image-s5', 'zoom-image-s5')
        "
        alt="Multiple tools in Pentview systems for better management of the same"
        class="img-fluid image-to-hover w-100"
        data-aos="fade-down"
        id="image-to-hover-s5"
        src="../../../assets/images/home/img-section-5.webp"
        width="525"
        height="400"
        priority
        loading="lazy"
      />
    </div>
    <div
      (click)="zoomOutImage('modal-zoom-image-s5', 'zoom-image-s5')"
      class="modal-zoom-image"
      id="modal-zoom-image-s5"
    >
      <img
        class="zoom-image"
        id="zoom-image-s5"
        alt="Multiple tools in Pentview systems for better management of the same"
        loading="lazy"
      />
    </div>
  </div>
</section>
<!-- Section 6 - What else do we offer? -->
<section class="home-section section-6 padding-section">
  <div
    class="container-row d-flex row justify-content-between align-items-center"
  >
    <h2 class="text-center title-h2 mx-auto w-100">
      {{ "HOME.section6.whatElseDoWeOffer" | translate }}
    </h2>
    <h3 class="text-center title-h3 mx-auto w-100 margin-bottom-80">
      {{ "HOME.section6.unlockARange" | translate }}
    </h3>
    <div
      class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 mx-0 mt-lg-0 margin-bottom-80"
    >
      <!-- card 1 - trader's room -->
      <div data-aos="example-anim2">
        <div class="card transparent-card h-100">
          <div class="card-body">
            <h4
              class="card-title d-flex justify-content-between align-items-center"
            >
              <span>{{
                "SHARED.slider.titlesSlider.titleCardTradersRoom" | translate
              }}</span>
              <img
                src="../../../assets/images/services/img-card-1-section-2.svg"
                width="38"
                height="38"
                alt="Trader's room"
                priority
                loading="lazy"
              />
            </h4>
            <p class="card-text">
              {{
                "SHARED.slider.paragraphsSlider.paragraphCardTradersRoom"
                  | translate
              }}
            </p>
          </div>
          <div class="card-footer p-0">
            <small class="text-white float-end">{{
              "SERVICES.additionalServices.section2.cards.included" | translate
            }}</small>
          </div>
        </div>
      </div>
      <!-- card 2 - affiliate's room -->
      <div data-aos="example-anim2">
        <div class="card transparent-card h-100">
          <div class="card-body">
            <h4
              class="card-title d-flex justify-content-between align-items-center"
            >
              <span>{{
                "SHARED.slider.titlesSlider.titleCardAffiliatesRoom" | translate
              }}</span>
              <img
                src="../../../assets/images/services/img-card-2-section-2.svg"
                width="38"
                height="38"
                alt="Affiliate's room"
                priority
                loading="lazy"
              />
            </h4>
            <p class="card-text">
              {{
                "SHARED.slider.paragraphsSlider.paragraphCardAffiliatesRoom"
                  | translate
              }}
            </p>
          </div>
          <div class="card-footer p-0">
            <small
              class="text-white float-end"
              [@fadeInDown]="{ value: fadeInDown, params: { timing: 3 } }"
              >{{
                "SERVICES.additionalServices.section2.cards.included"
                  | translate
              }}</small
            >
          </div>
        </div>
      </div>
      <!-- card 3 - website development -->
      <div data-aos="example-anim2">
        <div class="card transparent-card h-100">
          <div class="card-body">
            <h4
              class="card-title d-flex justify-content-between align-items-center"
            >
              <span>{{
                "SHARED.slider.titlesSlider.titleCardWebsiteDevelopment"
                  | translate
              }}</span>
              <img
                src="../../../assets/images/services/img-card-3-section-2.svg"
                width="37"
                height="30"
                alt="Website development"
                priority
                loading="lazy"
              />
            </h4>
            <p class="card-text">
              {{
                "SHARED.slider.paragraphsSlider.paragraphCardWebsiteDevelopment"
                  | translate
              }}
            </p>
          </div>
          <div class="card-footer p-0">
            <small
              class="text-white float-end"
              [@fadeInDown]="{ value: fadeInDown, params: { timing: 3 } }"
              >{{
                "SERVICES.additionalServices.section2.cards.included"
                  | translate
              }}</small
            >
          </div>
        </div>
      </div>
      <!-- card 4 - psp connection -->
      <div data-aos="example-anim2">
        <div class="card transparent-card h-100">
          <div class="card-body">
            <h4
              class="card-title d-flex justify-content-between align-items-center"
            >
              <span>{{
                "SHARED.slider.titlesSlider.titleCardPspConnection" | translate
              }}</span>
              <img
                src="../../../assets/images/services/img-card-4-section-2.svg"
                width="38"
                height="38"
                alt="PSP connection"
                priority
                loading="lazy"
              />
            </h4>
            <p class="card-text">
              {{
                "SHARED.slider.paragraphsSlider.paragraphCardPspConnection"
                  | translate
              }}
            </p>
          </div>
          <div class="card-footer p-0">
            <small class="text-white float-end">{{
              "SERVICES.additionalServices.section2.cards.included" | translate
            }}</small>
          </div>
        </div>
      </div>
      <!-- card 5 - ip telephones -->
      <div data-aos="example-anim2">
        <div class="card transparent-card h-100">
          <div class="card-body">
            <h4
              class="card-title d-flex justify-content-between align-items-center"
            >
              <span>{{
                "SHARED.slider.titlesSlider.titleCardIpTelephones" | translate
              }}</span>
              <img
                src="../../../assets/images/services/img-card-5-section-2.svg"
                width="38"
                height="39"
                alt="IP telephones"
                priority
                loading="lazy"
              />
            </h4>
            <p class="card-text">
              {{
                "SHARED.slider.paragraphsSlider.paragraphCardIpTelephones"
                  | translate
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- card 6 - bank account -->
      <div data-aos="example-anim2">
        <div class="card transparent-card h-100">
          <div class="card-body">
            <h4
              class="card-title d-flex justify-content-between align-items-center"
            >
              <span>{{
                "SHARED.slider.titlesSlider.titleCardBankAccount" | translate
              }}</span>
              <img
                src="../../../assets/images/services/img-card-6-section-2.svg"
                width="32"
                height="44"
                alt="Bank account"
                priority
                loading="lazy"
              />
            </h4>
            <p class="card-text">
              {{
                "SHARED.slider.paragraphsSlider.paragraphCardBankAccount"
                  | translate
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- button section -->
    <div class="d-flex justify-content-center align-items-center m-0">
      <a
        class="btn get-trial-button text-white d-flex justify-content-center align-items-center"
        href="/services/additional-services-for-your-brokerage"
        style="width: 26.25rem;"
      >
        {{ "HOME.section3.learnMore" | translate }}
      </a>
    </div>
  </div>
</section>
<!-- Section 7 - Interested? -->
<section class="home-section section-7 padding-section">
  <div
    class="container-row d-flex row justify-content-between align-items-center"
  >
    <div class="col-12 col-lg-6 mx-0 mb-5 mb-lg-0 p-0 pe-lg-5">
      <h2 class="title-h2" data-aos="fade-down">
        {{ "HOME.section7.interested" | translate }}
      </h2>
      <h3
        class="text-white title-h3 mx-0"
        data-aos="fade-down"
        data-aos-delay="100"
      >
        {{ "HOME.section7.letUsHelpYou" | translate }}
      </h3>
      <p
        class="text-white paragraph-section-7"
        data-aos="fade-down"
        data-aos-delay="200"
      >
        {{ "HOME.section7.paragraph" | translate }}
      </p>
      <a href="/contacts-us">
        <button
          title="Contact us"
          class="get-trial-button"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          {{ "HOME.section7.contactUs" | translate }}
        </button>
      </a>
    </div>
    <div class="col-12 col-lg-6 m-0 p-0">
      <img
        (click)="
          zoomImage('image-to-hover-s7', 'modal-zoom-image-s7', 'zoom-image-s7')
        "
        alt="Constant updates for better service to our partners and their clients with customizable panels"
        class="img-fluid image-to-hover w-100"
        data-aos="fade-down"
        id="image-to-hover-s7"
        src="../../../assets/images/home/img-section-7.webp"
        width="570"
        height="340"
        priority
        loading="lazy"
      />
    </div>
    <div
      (click)="zoomOutImage('modal-zoom-image-s7', 'zoom-image-s7')"
      class="modal-zoom-image"
      id="modal-zoom-image-s7"
    >
      <img
        class="zoom-image"
        id="zoom-image-s7"
        alt="platform's user interface picture zoomed"
        loading="lazy"
      />
    </div>
  </div>
</section>
