import { Component } from '@angular/core';
import { ScrollService } from '../utils/scrollService';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  constructor(private scrollService: ScrollService){};
  scrollToTop() {
    this.scrollService.irArriba();
  }
}
